:root {
  --primary-color: #2dbdf2;
  --secondary-color: #3b3839;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.StripeElement {
  margin: 15px auto;
  padding: 10px 12px;
  color: #32325d;
  background-color: white;
  border: 1px solid transparent;
  border-radius: 4px;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.product {
  width: 100%;
  max-width: 450px;
  margin: auto;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
  border-radius: 10px;
  overflow: hidden;
}

.btn-pay {
  display: block;
  width: 100%;
  border: none;
  color: #fff;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
}

.product-info {
  padding: 0 16px;
}

h3.product-title {
  font-size: 28px;
  margin-bottom: 15px;
}

h4.product-price {
  font-size: 24px;
  margin: 0;
  margin-bottom: 30px;
  color: #777;
  font-weight: 500;
}

/* toast styles */

.toast {
  position: fixed;
  /* overflow-y: auto; */
  top: 10px;
  right: 20px;
}
.toastContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.toastContainerItem {
  margin: 5px 0px;
  padding: 2px;
  border-radius: 4px;
  width: 100%;
  min-height: 100px;
  word-wrap: break-word;
  background-color: red;
  box-shadow: 4px 4px 15px 2px rgba(black);
  color: #fff;
  transition: 0.2s;
}
.toastContainerItem info {
  background-color: green;
}
.toastContainerItem danger {
  background-color: red;
}
.toastClose {
  cursor: pointer;
  position: relative;
  top: 5px;
  font-size: 20px;
  font-weight: 800;
}

/* add custom css to replace button,background etc color */
.btn-primary {
  background: var(--primary-color);
  border: none;
}

.btn-primary:hover {
  background: var(--secondary-color);
  border: none;
}
.btn-primary.focus,
.btn-primary:focus {
  background: var(--primary-color);
  border: none;
}
.recharts-rectangle {
  fill: var(--primary-color);
}
.text-primary {
  color: var(--primary-color) !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: var(--primary-color) !important;
}

.badge,
.bg-success {
  background: var(--primary-color) !important;
  color: #fff;
}
.bg-secondary {
  background: var(--secondary-color) !important;
  color: #fff;
}
.bg-warning {
  background: orange !important;
  color: #fff;
}

.bg-danger {
  background: red !important;
  color: #fff;
}
path.recharts-legend-icon {
  fill: var(--primary-color);
}
.recharts-legend-item-text {
  color: var(--primary-color) !important;
}

.autoCapital:first-letter {
  text-transform: uppercase;
}

.card {
  margin-bottom: 5px;
}
.card,
.navbar {
  border-radius: 10px !important;
}

button.accordion-button {
  background-color: transparent;
  border: 0px;
  font-size: 18px;
}

.customcardBody {
  display: grid;
  place-items: center;
  /* padding: unset !important; */
}

.borderColorsX {
  border: 2px solid rgb(107, 107, 236) !important;
  outline: none;
}
.borderColorsY {
  border: 1px solid rgb(209, 209, 209) !important;
  outline: none;
}

.StripeElement {
  border: 1px solid #ced4da !important;
  padding: 1rem;
  border-radius: 5px;
  font-size: 1.25rem;
}
