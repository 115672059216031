/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
    min-width: 250px;
    max-width: 250px;
    background: #fff;
    color: #000;
    margin-left: -250px;
    transition: all 0.5s;
  }
  
  .sidebar.is-open {
    margin-left: 0;
    transition: 0.5s;
  }
  
  .sidebar-header {
    background: #fff;
    color:#000;
  }
  
  .sidebar-header h3 {
    color: #000;
    padding: 1em;
  }
  
  .sidebar ul p {
    color: #000;
    padding: 10px;
  }
  
  .menu-open {
    background: #fff;
  }
  
  .nav-item:hover {
    color: #7386d5;
    background: #fff;
  }
  
  .items-menu {
    color: #000;
    background: #fff;
  }
  
  li a.dropdown-toggle::after {
    display: inline-flex;
    position: relative;
    left: 48%;
    top: 10%;
 
  }
  li a {
    color: #000
  }
  
  .sidebar-header > span {
    position: relative;
    float: right;
    margin: 0.5em;
    font-size: 2rem;
    cursor: pointer;
    display: none;
  }
  .side-menu {
    height: calc(100vh - 130px);
    overflow-y: scroll;
  }
  
  .side-menu::-webkit-scrollbar {
    width: 6px;
  }
  
  .side-menu::-webkit-scrollbar-thumb {
    background: #2dbdf2;
    border-radius: 3px;
  }
  
  .side-menu::-webkit-scrollbar-thumb:hover {
    background: #2dbdf4;
  }
  
  /* ---------------------------------------------------
      CONTENT STYLE
  ----------------------------------------------------- */
  .content {
    padding: 20px;
    margin-left: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  @media only screen and (max-width: 500px) {
    body {
      /* overflow: hidden; */
    }
  
    .content.is-open {
      /* margin-left: 100%; */
      display: none
    }
  
    .sidebar.is-open {
      min-width: 100%;
      max-width: 100%;
      margin-left: 0;
      transition: all 0.5s, height 0s;
    }
  
    .sidebar.is-open > .sidebar-header span {
      display: unset;
    }
  
    li a.dropdown-toggle::after {
      display: inline-block;
      position: relative;
      /* left: 68%; */
    }
  
  }
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
}
  